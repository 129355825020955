import envConfig, { setEnvConfig } from '@a24group/react-context-envconfig';

type TEnvConfig = {
  IS_LOCAL?: boolean,
  PUBLIC_PATH: string,
  IS_DEVELOPMENT: boolean,
  REACT_APP_API_DOMAIN: string,
  REACT_APP_AUTH_DOMAIN: string,
  REACT_APP_ENTITY_ACTION?: string,
  REACT_APP_BASE_URL: string,
};

// @ts-ignore
setEnvConfig(webpackEnvConfig);

export default envConfig as TEnvConfig;
